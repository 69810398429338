import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {LayoutState} from '@app-states/layout';
import {Select} from '@ngxs/store';
import {BehaviorSubject, map, Observable, tap} from 'rxjs';

export const LAYOUT_SHORT_TYPES_MAP = {
  [Breakpoints.Small]: Breakpoints.Handset,
  [Breakpoints.XSmall]: Breakpoints.Handset,
  [Breakpoints.Medium]: Breakpoints.Web,
  [Breakpoints.Large]: Breakpoints.Web,
  [Breakpoints.XLarge]: Breakpoints.Web,
};

export const LAYOUT_TYPES = [
  Breakpoints.Small,
  Breakpoints.XSmall,
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge,
];

@Injectable()
export class LayoutTypeService {
  @Select(LayoutState.header)
  public readonly header$!: Observable<{ header?:string,subheader?:string }>;
  @Select(LayoutState.isHomePage)
  public readonly isHomePage$!: Observable<boolean>;

  private readonly layoutSubject$ = new BehaviorSubject<string>(
    Breakpoints.Handset
  );

  get isDesktop$(): Observable<boolean> {
    return this.layoutSubject$.pipe(map((el) => el !== Breakpoints.Handset));
  }
  get layoutType$(): Observable<string> {
    return this.layoutSubject$.asObservable();
  }

  get snapshotLayoutType(): string {
    return this.layoutSubject$.value;
  }

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(LAYOUT_TYPES)
      .pipe(
        tap((result) => {
          let type;
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              type = LAYOUT_SHORT_TYPES_MAP[query];
              break;
            }
          }

          this.layoutSubject$.next(type ?? Breakpoints.Handset);
        })
      )
      .subscribe();
  }

  is(size: string): boolean {
    return size === this.snapshotLayoutType;
  }
}
